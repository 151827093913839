import config from '@configuration/clients/chatprise/firebase.json';
import { ClientConfiguration } from '@configuration/models/client.interface';

const configuration: ClientConfiguration = {
  title: 'Chatprise',
  host: 'https://backend-chf.zonetacts.com',
  ids: {
    Apple: {
      iOS: 'chatforce/id1519416278',
    },
    Google: {
      android: 'com.atlabs.zonetacts.chatforce',
    },
  },
  integrations: {
    firebase: {
      config,
      cloudMessaging: true,
    },
  },
};
export default configuration;
