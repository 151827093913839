{
  "apiKey": "AIzaSyAJqdSZi-q77EOpC4szbq-XIaqmGBXdGCk",
  "authDomain": "chatforce-zonetacts.firebaseapp.com",
  "databaseURL": "https://chatforce-zonetacts.firebaseio.com",
  "projectId": "chatforce-zonetacts",
  "storageBucket": "chatforce-zonetacts.appspot.com",
  "messagingSenderId": "672132523535",
  "appId": "1:672132523535:web:f4694619be9c7e0c8de57a",
  "measurementId": "G-LQY4KETWN7"
}
