import { computed, inject, isDevMode } from '@angular/core';

import {
  getState,
  patchState,
  signalStore,
  watchState,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';

import { AuthState, initialAuthState } from '@state/state/auth.state';

import { StorageService } from '@services/storage/storage.service';

const TAG = 'Auth Store';

export type AuthStore = InstanceType<typeof AuthStore>;
export const AuthStore = signalStore(
  withState(initialAuthState), // ! If we initialize the state here, it will overwrite the state from the storage on store initialization
  withMethods((store, storageService = inject(StorageService)) => ({
    // Generic setters
    set<K extends keyof AuthState>(property: K, value: AuthState[K]): void {
      patchState(store, (state) => ({ [property]: value }));
    },

    reset(property?: keyof AuthState): void {
      if (!property) {
        patchState(store, (state) => initialAuthState);
        return;
      }
      patchState(store, (state) => ({
        [property]: initialAuthState[property],
      }));
    },

    // Hydration methods
    hydrate: (): void => {
      console.debug(`[${TAG}] Starting hydration...`);
      if (storageService.check(TAG)) {
        const stored = storageService.get<AuthState>(TAG);
        console.debug(`[${TAG}] Hydrating...`, stored);
        if (stored !== null)
          // ? Only restore some properties of the stored state
          patchState(store, (state) => stored);
      } else {
        console.debug(`[${TAG}] No previous state found`);
      }
    },

    persist(): void {
      console.debug(`[${TAG}] Persisting state...`);
      storageService.set(TAG, getState(store));
    },

    // Custom methods
    register(): void {
      // ? When a user finishes the registration process
      this.set('registered', true);
      this.set('info', undefined);
    },
  })),
  withComputed(({ me }) => ({
    authenticated: computed(() => !!me()),
  })),
  withHooks({
    onInit: (store) => {
      if (isDevMode()) console.debug(`[${TAG}] Store initialized`);

      // ? Effects
      // https://next.ngrx.io/guide/signals/signal-store/state-tracking#using-getstate-and-effect
      // effect(() => {}, {});

      store.hydrate();

      watchState(store, (state) => {
        if (isDevMode()) console.debug(`[${TAG}] State updated:`, state);
        store.persist();
      });

      /*
      ! For auth state, we want to store all changes
      if (isDevMode())
        watchState(store, (state) =>
          console.debug(`[${TAG}] State updated:`, state)
        );

      // ? Instead of watching the state and persisting it everytime it changes, we persist it every x minutes
      const SAVE_INTERVAL_MINUTES = 5; // Set the interval time in minutes
      interval(SAVE_INTERVAL_MINUTES * 60 * 1000)
        .pipe(takeUntilDestroyed())
        .subscribe(() => {
          if (isDevMode())
            console.debug(`[${TAG}] Persisting state at interval`);
          store.persist();
        });
      */
    },
    onDestroy: (store) => {
      if (isDevMode())
        console.debug(`[${TAG}] Store destroyed`, getState(store));
      store.persist();
    },
  })
);
