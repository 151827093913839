/*
? Local storage and session storage are both web storage options available in browsers, but they have some key differences:

- Scope of Data
Local Storage: Data stored in local storage persists even after the browser is closed. It's designed for long-term storage and remains until explicitly removed by the web application or the user.
Session Storage: Data stored in session storage is available only for the duration of the page session. It's cleared when the browser tab or window is closed, making it suitable for temporary storage during a user's session.

- Storage Limit
Local Storage: Typically allows for a larger amount of data to be stored (usually around 5MB or more per domain).
Session Storage: Generally offers a smaller storage limit compared to local storage, often around 5-10MB, and is limited to the duration of the session.

- Access
Local Storage: Data stored here is accessible across browser tabs and windows within the same domain.
Session Storage: Data is accessible within the same tab or window, limiting its availability compared to local storage.

- Persistence
Local Storage: Persists across browser sessions. Data remains until explicitly cleared by the application or the user.
Session Storage: Data is temporary and is cleared once the session ends, i.e., when the tab/window is closed.

- Common Use Cases
Local Storage: Suitable for storing user preferences, authentication tokens, and cached data that needs to persist between sessions.
Session Storage: Useful for storing temporary information, such as form data or state that's needed only during the current browsing session.

? Both local storage and session storage have some security considerations:

- Same-Origin Policy
Both local and session storage follow the same-origin policy, which means that web pages can only access data stored in these storage mechanisms if they originate from the same domain/protocol/port combination. This helps prevent unauthorized access to data by scripts from different origins.

- Security Concerns
Local Storage: Data stored in local storage is susceptible to cross-site scripting (XSS) attacks. If a malicious script manages to execute on a page, it can potentially access and modify data in the local storage of that domain.
Session Storage: Similarly, session storage is also vulnerable to XSS attacks during the current session. However, as it clears when the session ends (i.e., when the tab/window is closed), the exposure time is shorter compared to local storage.

- Data Isolation
Both storages are isolated by origin, meaning that scripts from one domain cannot access the storage of another domain. This prevents unauthorized access to data stored by other websites.

- HTTPS Encryption
Both storage mechanisms are subject to the security measures of the browser. They are generally considered secure within the context of the browser's security protocols. However, it's essential to use them over HTTPS connections to prevent data interception and man-in-the-middle attacks.

- Sensitive Data Handling:
Avoid storing sensitive information like passwords or authentication tokens directly in local or session storage without proper encryption or additional security measures. It's generally safer to store such sensitive data in memory or utilize secure authentication mechanisms.

? In summary, while local storage and session storage offer secure storage within the browser, they are susceptible to certain vulnerabilities like XSS attacks. Best practices involve encrypting sensitive data, ensuring secure connections (HTTPS), and being cautious with the type of information stored.
*/

import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService
  ) {
    return;
  }

  // TODO: Handle encryption and decryption of data in web storage
  // ? Use the user token to encrypt and decrypt data stored in local/session storage, or receive an encryption key from the server?
  set<T>(
    key: string,
    value: T,
    options?: {
      expiration?: Date | 'session';
      encrypt?: boolean;
    }
  ): void {
    if (options?.expiration === 'session') {
      this.sessionStorageService.set(key, value, {
        ...options,
        expiration: undefined,
      });
    } else
      this.localStorageService.set(key, value, {
        ...options,
        expiration: options?.expiration,
      });
  }

  check(key: string): boolean {
    return (
      this.sessionStorageService.check(key) ||
      this.localStorageService.check(key)
    );
  }

  get<T>(key: string, mapper?: (value: any) => T): T | null {
    return (
      this.sessionStorageService.getWithDate<T>(key, mapper)?.data ||
      this.localStorageService.getWithDate<T>(key, mapper)?.data ||
      null
    );
  }

  getWithDate<T>(
    key: string,
    mapper?: (value: any) => T
  ): { date: Date; data: T } | null {
    return (
      this.sessionStorageService.getWithDate<T>(key, mapper) ||
      this.localStorageService.getWithDate<T>(key, mapper) ||
      null
    );
  }

  delete(key: string): void {
    this.sessionStorageService.delete(key);
    this.localStorageService.delete(key);
  }

  clear(): void {
    this.sessionStorageService.clear();
    this.localStorageService.clear();
  }
}
