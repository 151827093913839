import { isDevMode } from '@angular/core';

import firebaseConfig from '@configuration/environments/firebase.json';
import {
  ClientConfiguration,
  Identifiers,
  Integrations,
  UI,
} from '@configuration/models/client.interface';
import { EnvironmentConfiguration } from '@configuration/models/environment.interface';

import { Environment } from '@enums/environment.enum';

const baseConfiguration: ClientConfiguration = {
  title: 'Zonetacts',
  // logo: 'assets/images/icon.png',
  host: 'http://localhost',
  // port: 8080,
  controller: 'api/v2.0',
  encryption: true,
  ids: {
    Apple: {
      iOS: 'chatforce/id1519416278',
      // mac: 'chatforce/id1519416278',
    },
    Google: {
      android: 'com.atlabs.zonetacts.chatforce',
    },
  },
  ui: {
    header: {
      preferLogo: true,
      showCompanyName: false,
    },
  },
  integrations: {},
};

export default class Configuation implements ClientConfiguration {
  title!: string;
  company!: string;
  logo!: string;
  host!: string;
  port?: number;
  controller?: string;
  encryption?: boolean;
  ids!: Identifiers;
  integrations!: Integrations;
  ui!: UI;

  apiUrl!: string;

  constructor(
    client: ClientConfiguration,
    environment: EnvironmentConfiguration
  ) {
    Object.assign(this, baseConfiguration);
    Object.assign(this, client);

    if (environment.host) {
      this.host = environment.host;
      if (environment.port) this.port = environment.port;
    }
    this.company = this.title;

    // Prefix the title
    if (isDevMode()) {
      // ? With [Local] if the host is localhost
      if (this.host.includes('localhost')) this.title = `[Local] ${this.title}`;
      // ? With [Dev] if the host is not localhost and the app is in development mode
      else this.title = `[Dev] ${this.title}`;
    } else {
      // ? With [Staging] if not in development mode and the host is our staging server
      if (this.host === 'https://backend-test.zonetacts.com')
        this.title = `[Staging] ${this.title}`;
    }

    // Regex that checks if the host ends with a port
    if (!this.controller || this.controller === '') {
      this.apiUrl =
        isDevMode() &&
        ![Environment.Production, Environment.Staging].includes(
          environment.environment
        )
          ? ''
          : this.origin;
    } else {
      this.apiUrl =
        isDevMode() &&
        ![Environment.Production, Environment.Staging].includes(
          environment.environment
        )
          ? this.controller
          : `${this.origin}/${this.controller}`;
    }

    // ? Use the development Firebase configuration if the environment is development or staging
    if (
      (environment.environment === Environment.Development ||
        environment.environment === Environment.Staging) &&
      this.host === 'https://backend-test.zonetacts.com'
    ) {
      this.integrations.firebase = {
        ...this.integrations.firebase,
        config: firebaseConfig,
      };
    }

    if (isDevMode()) console.debug('[Configuration] Configuration:', this);
  }

  // ? The origin is a unique combination of host and port, and is used to identify the server
  get origin(): string {
    // Regex that checks if the host ends with a port
    if (/:${this.port}$/.test(this.host)) return this.host;
    else if (this.port) return `${this.host}:${this.port}`;
    else return this.host;
  }

  get sockets(): SocketIOClient.ConnectOpts {
    return {
      // ? Socket settings
      transports: ['websocket'],
      upgrade: false,
      forceNew: true,

      // ? Connection settings
      autoConnect: false,
      timeout: 20000,

      // ? Reconnection settings
      ...(isDevMode()
        ? {
            reconnection: true,
            reconnectionAttempts: 3,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            randomizationFactor: 0.5,
          }
        : {
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 10000,
            reconnectionDelayMax: 60000,
            randomizationFactor: 0.5,
            secure: true,
          }),
    };
  }
}
