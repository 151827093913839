import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor() {
    return;
  }

  set<T>(
    key: string,
    value: T,
    options?: {
      expiration?: Date;
    }
  ): void {
    localStorage.setItem(
      key,
      JSON.stringify({
        data: value,
        date: new Date(),
        expiration: options?.expiration,
      })
    );
  }

  check(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  get<T>(key: string, mapper?: (value: any) => T): T | null {
    return this.getWithDate<T>(key, mapper)?.data || null;
  }

  getWithDate<T>(
    key: string,
    mapper?: (value: any) => T
  ): { date: Date; data: T } | null {
    const json = localStorage.getItem(key);
    if (!json) return null;

    const parsed = JSON.parse(json);
    if (parsed.date) {
      const date = new Date(parsed.date);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const expired: boolean =
        parsed.expiration && diff > parsed.expiration.getTime();

      if (expired) {
        localStorage.removeItem(key);
        return null;
      }
    }
    return {
      date: new Date(parsed.date),
      data: mapper ? mapper(parsed.data) : (parsed.data as T),
    };
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
